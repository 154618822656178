import React from "react"
import Home from "./Home"

function IndexPage() {
  return (
    <>
      <Home />
    </>
  )
}

export default IndexPage
